import * as CookieConsent from "vanilla-cookieconsent";

// Alpine data component for cookie settings
export const cookieSettingsComponent = () => ({
	open() {
		window.cookieConsent.showPreferences();
	},
});

// Initialize cookie consent
export const initCookieConsent = () => {
	window.cookieConsent = CookieConsent;

	// Run the cookie consent banner
	CookieConsent.run({
		guiOptions: {
			consentModal: {
				layout: "bar",
				position: "bottom",
				equalWeightButtons: true,
				flipButtons: true,
			},
			preferencesModal: {
				layout: "box",
				position: "right",
				equalWeightButtons: true,
				flipButtons: false,
			},
		},

		categories: {
			necessary: {
				enabled: true,
				readOnly: true,
			},
			analytics: {
				enabled: false,
				readOnly: false,
			},
		},

		language: {
			default: "de",
			autoDetect: "document",
			translations: {
				de: {
					consentModal: {
						title: "Wir verwenden Cookies",
						description:
							"Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und Ihr Surferlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie zustimmen oder ablehnen möchten.",
						acceptAllBtn: "Alle akzeptieren",
						acceptNecessaryBtn:
							"Nur essenzielle Cookies akzeptieren",
						showPreferencesBtn: "Einstellungen verwalten",
					},
					preferencesModal: {
						title: "Cookie-Einstellungen verwalten",
						acceptAllBtn: "Alle akzeptieren",
						acceptNecessaryBtn:
							"Nur essenzielle Cookies akzeptieren",
						savePreferencesBtn: "Auswahl speichern",
						closeIconLabel: "Schließen",
						sections: [
							{
								title: "Cookie-Verwendung",
								description:
									"Wir verwenden Cookies, um die grundlegenden Funktionen der Website zu gewährleisten und Ihr Surferlebnis zu verbessern. Sie können für jede Kategorie wählen, ob Sie zustimmen oder ablehnen möchten.",
							},
							{
								title: "Unbedingt erforderliche Cookies",
								description:
									"Diese Cookies sind für das ordnungsgemäße Funktionieren der Website unerlässlich. Sie ermöglichen die Nutzung sicherer Bereiche, Formulare und weitere grundlegende Funktionen. Ohne diese Cookies kann die Website nicht richtig funktionieren. Diese beinhalten den CRAFT_CSRF_TOKEN für Sicherheitsabfragen und CraftSessionId für Ihre Sitzung.",
								linkedCategory: "necessary",
							},
							{
								title: "Analyse-Cookies (Matomo)",
								description:
									"Diese Cookies und Skripte helfen uns zu verstehen, wie Besucher mit unserer Website interagieren. Wir nutzen Matomo, eine datenschutzfreundliche Analysesoftware, zur Verbesserung unserer Website. Alle Daten werden anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren.",
								linkedCategory: "analytics",
							},
							{
								title: "Weitere Informationen",
								description:
									'Bei Fragen zu unserer Cookie-Richtlinie und Ihren Wahlmöglichkeiten kontaktieren Sie uns bitte über <a href="#contact-page">unsere Kontaktseite</a>.',
							},
						],
					},
				},
				en: {
					consentModal: {
						title: "We use cookies",
						description:
							"We use cookies to ensure the basic functionality of the website and to enhance your browsing experience. You can choose to opt in or out of each category.",
						acceptAllBtn: "Accept all",
						acceptNecessaryBtn: "Accept only essential cookies",
						showPreferencesBtn: "Manage preferences",
					},
					preferencesModal: {
						title: "Manage cookie preferences",
						acceptAllBtn: "Accept all",
						acceptNecessaryBtn: "Accept only essential cookies",
						savePreferencesBtn: "Save preferences",
						closeIconLabel: "Close",
						sections: [
							{
								title: "Cookie Usage",
								description:
									"We use cookies to ensure the basic functionality of the website and to enhance your browsing experience. You can choose to opt in or out of each category.",
							},
							{
								title: "Strictly Necessary Cookies",
								description:
									"These cookies are essential for the proper functioning of the website. They enable secure areas, forms, and other basic features. Without these cookies, the website cannot function properly. These include the CRAFT_CSRF_TOKEN for security queries and CraftSessionId for your session.",
								linkedCategory: "necessary",
							},
							{
								title: "Analytics Cookies (Matomo)",
								description:
									"These cookies and scripts help us understand how visitors interact with our website. We use Matomo, a privacy-friendly analytics software, to improve our website. All data is anonymized and cannot be used to identify you.",
								linkedCategory: "analytics",
							},
							{
								title: "More Information",
								description:
									'For any questions regarding our cookie policy and your choices, please contact us via <a href="#contact-page">our contact page</a>.',
							},
						],
					},
				},
				cs: {
					consentModal: {
						title: "Používáme cookies",
						description:
							"Používáme cookies k zajištění základních funkcí webových stránek a ke zlepšení vašeho prohlížení. Můžete si vybrat, zda chcete jednotlivé kategorie povolit nebo zakázat.",
						acceptAllBtn: "Přijmout vše",
						acceptNecessaryBtn:
							"Přijímat pouze nezbytné soubory cookie",
						showPreferencesBtn: "Spravovat nastavení",
					},
					preferencesModal: {
						title: "Správa nastavení cookies",
						acceptAllBtn: "Přijmout vše",
						acceptNecessaryBtn:
							"Přijímat pouze nezbytné soubory cookie",
						savePreferencesBtn: "Uložit nastavení",
						closeIconLabel: "Zavřít",
						sections: [
							{
								title: "Použití cookies",
								description:
									"Používáme cookies k zajištění základních funkcí webových stránek a ke zlepšení vašeho prohlížení. Můžete si vybrat, zda chcete jednotlivé kategorie povolit nebo zakázat.",
							},
							{
								title: "Nezbytně nutné cookies",
								description:
									"Tyto cookies jsou nezbytné pro správné fungování webových stránek. Umožňují zabezpečené oblasti, formuláře a další základní funkce. Bez těchto cookies nemohou webové stránky správně fungovat. Patří mezi ně CRAFT_CSRF_TOKEN pro bezpečnostní dotazy a CraftSessionId pro vaši relaci.",
								linkedCategory: "necessary",
							},
							{
								title: "Analytické cookies (Matomo)",
								description:
									"Tyto cookies a skripty nám pomáhají pochopit, jak návštěvníci interagují s našimi webovými stránkami. Používáme Matomo, analytický software respektující soukromí, ke zlepšení našich webových stránek. Všechna data jsou anonymizována a nemohou být použita k vaší identifikaci.",
								linkedCategory: "analytics",
							},
							{
								title: "Další informace",
								description:
									'V případě jakýchkoli dotazů ohledně naší politiky cookies a vašich možností nás prosím kontaktujte prostřednictvím <a href="#contact-page">naší kontaktní stránky</a>.',
							},
						],
					},
				},
			},
		},

		onAccept: () => {
			// Check if analytics category is accepted
			if (window.cookieConsent.acceptedCategory("analytics")) {
				// TODO: Enable Matomo tracking
			}
		},

		onChange: ({ changedCategories }) => {
			// When analytics consent changes
			if (changedCategories.includes("analytics")) {
				if (window.cookieConsent.acceptedCategory("analytics")) {
					// TODO: Enable Matomo tracking
				} else {
					// TODO: Disable Matomo tracking
				}
			}
		},
	});
};

export default {
	init: initCookieConsent,
	settingsComponent: cookieSettingsComponent,
};
