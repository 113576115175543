// iframeManager.js

import "@orestbida/iframemanager/src/iframemanager";

// Initialize and export the iframe manager
const initIframeManager = () => {
	const im = window.iframemanager();

	im.run({
		currLang: document.documentElement.lang?.split("-")[0] || "de",

		// This is the key integration point with CookieConsent
		onChange: ({ changedServices, eventSource }) => {
			if (eventSource.type === "click") {
				const servicesToAccept = [
					...(window.CookieConsent.getUserPreferences()
						.acceptedServices["external"] || []),
					...changedServices,
				];

				window.CookieConsent.acceptService(
					servicesToAccept,
					"external",
				);
			}
		},

		services: {
			// YouTube videos
			youtube: {
				embedUrl: "https://www.youtube-nocookie.com/embed/{data-id}",
				iframe: {
					allow: "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; web-share",
					allowFullscreen: true,
					loading: "lazy",
				},
				languages: {
					de: {
						notice: "Dieses Video wird von einem Drittanbieter (YouTube) gehostet. Durch das Laden des Videos werden Daten mit YouTube geteilt.",
						loadBtn: "Video laden",
						loadAllBtn: "Immer laden",
					},
					en: {
						notice: "This video is hosted by a third party (YouTube). By loading the video, data will be shared with YouTube.",
						loadBtn: "Load video",
						loadAllBtn: "Always load",
					},
					cs: {
						notice: "Toto video je hostováno třetí stranou (YouTube). Načtením videa budou data sdílena s YouTube.",
						loadBtn: "Načíst video",
						loadAllBtn: "Vždy načíst",
					},
				},
			},

			// Vimeo videos
			vimeo: {
				embedUrl: "https://player.vimeo.com/video/{data-id}",
				iframe: {
					allow: "autoplay; fullscreen; picture-in-picture",
					allowFullscreen: true,
					loading: "lazy",
				},
				languages: {
					de: {
						notice: "Dieses Video wird von einem Drittanbieter (Vimeo) gehostet. Durch das Laden des Videos werden Daten mit Vimeo geteilt.",
						loadBtn: "Video laden",
						loadAllBtn: "Immer laden",
					},
					en: {
						notice: "This video is hosted by a third party (Vimeo). By loading the video, data will be shared with Vimeo.",
						loadBtn: "Load video",
						loadAllBtn: "Always load",
					},
					cs: {
						notice: "Toto video je hostováno třetí stranou (Vimeo). Načtením videa budou data sdílena s Vimeo.",
						loadBtn: "Načíst video",
						loadAllBtn: "Vždy načíst",
					},
				},
			},

			// Google Maps
			googlemaps: {
				embedUrl: "https://www.google.com/maps/embed?pb={data-id}",
				iframe: {
					loading: "lazy",
				},
				languages: {
					de: {
						notice: "Diese Karte wird von Google Maps bereitgestellt. Beim Laden werden Daten mit Google geteilt.",
						loadBtn: "Karte laden",
						loadAllBtn: "Immer laden",
					},
					en: {
						notice: "This map is provided by Google Maps. By loading it, data will be shared with Google.",
						loadBtn: "Load map",
						loadAllBtn: "Always load",
					},
					cs: {
						notice: "Tato mapa je poskytována Mapami Google. Jejich načtením budou data sdílena s Googlem.",
						loadBtn: "Načíst mapu",
						loadAllBtn: "Vždy načíst",
					},
				},
			},

			// Generic iframe handler for all other iframe types
			generic: {
				embedUrl: "{data-src}",
				iframe: {
					loading: "lazy",
				},
				languages: {
					de: {
						notice: "Dieser Inhalt wird von einem Drittanbieter gehostet. Beim Laden werden Daten mit dem Anbieter geteilt.",
						loadBtn: "Inhalt laden",
						loadAllBtn: "Immer laden",
					},
					en: {
						notice: "This content is hosted by a third party. By loading it, data will be shared with the provider.",
						loadBtn: "Load content",
						loadAllBtn: "Always load",
					},
					cs: {
						notice: "Tento obsah je hostován třetí stranou. Načtením budou data sdílena s poskytovatelem.",
						loadBtn: "Načíst obsah",
						loadAllBtn: "Vždy načíst",
					},
				},
			},
		},
	});

	return im;
};

export default initIframeManager;
