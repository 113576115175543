import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import "./forms";
import CookieConsent, {
	cookieSettingsComponent,
} from "./consent/cookieConsent";
import initIframeManager from "./consent/iframeManager";

// Alpine plugins
Alpine.plugin(collapse);

// Register Alpine components
Alpine.data("cookieSettings", cookieSettingsComponent);

// Make Alpine global
window.Alpine = Alpine;

// Inits
CookieConsent.init();
initIframeManager();

// Start Alpine
Alpine.start();
